import React from "react";
import { getFile } from "../util/http";
import { InlineSpinner } from "./Loader";
import { ToastContext } from "./Toast";

function Resource({ id, name, extension, prettySize, fileName, url, path, isPublic, publicURL, ...rest }) {
  const [loading, setLoading] = React.useState(false);
  const toast = React.useContext(ToastContext);
  const handleOpen = async () => {
    //Public files are served directly
    if(isPublic === "true"){
      window.open(publicURL, '_blank');
      return;
    }
    setLoading(true);
    if (!fileName) {
      const urlTmp = rest?.path.split("/");
      const fallback = urlTmp[urlTmp.length - 1];
      fetchFile(fallback);
      return;
    }
    fetchFile(fileName);
  };
  const fetchFile = async (fileName) => {
    getFile(fileName)
      .then((res) => {
        setLoading(false);
        if (!res.data?.[0]) return;
        if (res.data?.[0]) {
          window.open(res.data[0], '_blank');
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.showToast("Please try again!", "error");
      });
  };
  return (
    <>
      <button
        id={id}
        className={`resource ${loading ? "disable" : ""}`}
        style={{ width: '100%', border: 0 }}
        onClick={handleOpen}
      >
        {loading && <InlineSpinner />}
        <div className="txt">
          <h3>{name}</h3>
          <p>
            <span>
              <i className="icon-download" />
              <b>{extension}</b>
            </span>
            <em>{prettySize}</em>
          </p>
        </div>
      </button>
    </>
  );
}

export default Resource;
