import React, { createContext, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
export const ToastContext = createContext({
  showToast: () => {},
  hideToast: () => {},
  config: {
    show: false,
    message: "",
    type: "success",
  },
});

export const ToastProvider = ({ children }) => {
  const toasterRef = useRef(null);
  const showToast = (message, type = "success") => {
    toasterRef.current = toast[type]?.(message);
  };

  const hideToast = () => {
    if (toasterRef.current !== null) {
      toast.remove(toasterRef.current);
    }
  };

  const toastConfig = {
    showToast,
    hideToast,
    config: {
      show: false,
      type: "success",
      message: "",
    },
  };

  return (
    <ToastContext.Provider value={toastConfig}>
      {children}
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 6000,
          style: {
            fontSize: "1.05rem",
            lineHeight: "1.25",
          },
          success: {
            style: {
              background: "#51a351",
              color: "white",
              textAlign: "left",
            },
            className: "success-toast",
          },
          error: {
            style: {
              background: "#bd362f",
              color: "white",
              textAlign: "left",
            },
          },
        }}
      />
    </ToastContext.Provider>
  );
};
